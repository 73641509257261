import styles from './Badge.module.scss';
import Icon from '@/components/icon/Icon';

export default function Badge({ title, modifier, icon }) {
    return (
        <div className={styles['badge'] + (modifier ? ' ' + modifier : '')}>
            <div className="badge__wrapper">
                <div className="badge__label">{title}</div>
                {icon && <Icon id={icon} modifier={' icon--badge'} />}
            </div>
        </div>
    );
}
