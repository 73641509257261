import React from 'react';
import { RenderType } from '@/src/api/v3/models/Showcase';
import ShowcasePlain from '@/components/v3/ShowcasePlain';
import ShowcaseSlider from '@/components/v3/ShowcaseSlider';
import ShowcaseTabs from '@/components/v3/ShowcaseTabs';
import ShowcaseSliderBig from '@/components/v3/ShowcaseSliderBig';

/**
 * @param {Showcase[]} showcases
 */
export default function Showcases({ showcases }) {
    return showcases.map((showcase) => {
        if (showcase.items.length === 0 && showcase.total === 0 && !showcase.subtitle) {
            return '';
        }
        switch (showcase.renderType) {
            case RenderType.SLIDER:
                return <ShowcaseSlider key={showcase.urn} showcase={showcase} />;
            case RenderType.PLAIN:
                return <ShowcasePlain key={showcase.urn} showcase={showcase} />;
            case RenderType.TABS:
                return <ShowcaseTabs key={showcase.urn} showcase={showcase} />;
            case RenderType.SLIDER_BIG:
                return <ShowcaseSliderBig key={showcase.urn} showcase={showcase} />;
            case RenderType.HTML:
                return <React.Fragment key={showcase.id}>{showcase.html}</React.Fragment>;
            default:
                return '';
        }
    });
}
