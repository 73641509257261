import styles from './Pagination.module.scss';
import Button from '@/components/button/Button';
import { useInView } from 'react-intersection-observer';

export default function Pagination({ isLoading, onLoadItems }) {
    return isLoading ? <PaginationLoading /> : <PaginationLoaded onLoadItems={onLoadItems} />;
}

function PaginationLoaded({ onLoadItems }) {
    const { ref, inView } = useInView({
        triggerOnce: true,
    });
    if (inView) {
        setTimeout(() => {
            onLoadItems();
        }, 0);
    }
    return (
        <section className={styles['pagination'] + ' pagination--show-more'} ref={ref}>
            <Button
                type={'text'}
                title={'Показать ещё'}
                onClick={onLoadItems}
                className={'pagination__btn button--large button--secondary'}
            />
        </section>
    );
}

function PaginationLoading() {
    return (
        <section className={styles['pagination'] + ' pagination--show-more'}>
            <Button
                submitting={true}
                type={'text'}
                title={'Показать ещё'}
                className={'pagination__btn button--large button--secondary'}
            />
        </section>
    );
}
