import styles from './../catalog/Catalog.module.scss';
import Cards, { DummyCards, getSliderStubClass } from '@/components/v3/Cards';
import Button from '@/components/button/Button';
import { Swiper } from 'swiper/react';
import useForceUpdate from '@/src/hooks/useForceUpdate';
import { useApiStore } from '@/src/stores/RootStoreProvider';
import { Navigation } from 'swiper/modules';

const disabledBtnClass = 'button--disabled',
    actionsClass = 'catalog__item-actions',
    sliderClass = 'catalog__item-slider',
    slideClass = 'catalog__item-slider-slide';

const getPropsByElements = (elements = 8) => {
    switch (elements) {
        case 4:
            return {
                desktop: 4,
                laptop: 3,
            };
        case 6:
            return {
                large: 6,
                desktop: 5,
                laptop: 4,
            };
        default:
            return {
                desktop: 8,
                laptop: 6,
            };
    }
};

const updateActions = (swiper, showcase) => {
    const showcaseId = showcase.getSelectorId();
    const actions = document.getElementById(`${showcaseId}-actions`);
    if (document.body.clientWidth < 600 || !actions) {
        return;
    }
    // noinspection JSUnresolvedFunction
    if (
        swiper.slides.length <= swiper.slidesPerViewDynamic() &&
        (showcase.total === undefined || swiper.slides.length === showcase.total)
    ) {
        actions.classList.add('h-hidden');
    } else {
        actions.classList.remove('h-hidden');
    }
};

/**
 * @param {Showcase} showcase
 */
function ShowcaseHeader({ showcase }) {
    return (
        <span className={styles['catalog__item-title']}>
            {showcase.urn ? (
                <Button
                    type={'link'}
                    href={showcase.getUrl()}
                    className={'catalog__item-title-link button--clear button--arrow-right'}
                    title={showcase.title}
                />
            ) : (
                showcase.title
            )}
        </span>
    );
}

/**
 * @param {Showcase} showcase
 */
export default function ShowcaseSlider({ showcase }) {
    const showcaseId = showcase.getSelectorId();
    return (
        <>
            <h2 className="h-hidden">{showcase.title}</h2>
            <div
                className={
                    styles['catalog__item'] +
                    ' catalog__item--slider' +
                    (showcase.items.length === 0 ? ' catalog__item--empty' : '')
                }
                data-name={showcase.title}
            >
                <div className={styles['catalog__item-header']}>
                    {showcase.showHeader ? <ShowcaseHeader showcase={showcase} /> : <span className="button__text" />}
                    <div className={styles[actionsClass]} id={`${showcaseId}-actions`}>
                        {showcase.showTotalButton && (
                            <Button
                                type={'link'}
                                title={'Все'}
                                className={'catalog__item-header-btn button--content button--secondary button--small'}
                                href={showcase.getUrl()}
                            />
                        )}
                        <Button
                            type={'button'}
                            icon={'arrow-left'}
                            title={'Предыдущий слайд'}
                            className={'catalog__item-slider-arrow button--secondary button--small button--icon-only'}
                            id={`${showcaseId}-prevButton`}
                        />
                        <Button
                            type={'button'}
                            icon={'arrow-right'}
                            title={'Следующий слайд'}
                            className={'catalog__item-slider-arrow button--secondary button--small button--icon-only'}
                            id={`${showcaseId}-nextButton`}
                        />
                    </div>
                </div>
                <Slider itemClass={showcase.getItemClass()} showcase={showcase} />
            </div>
        </>
    );
}

export function Slider({
    showcase,
    itemClass,
    spaceBetween = 16,
    slidesPerView = 1,
    wrapperClassName = '',
    sliderClassName = sliderClass,
    slideClassName = slideClass,
    needBreakpoints = true,
    breakpoints = null,
    cards = null,
    onSwiperInit,
    onShowcaseUpdate,
    loop = false,
}) {
    const { api } = useApiStore();
    const dummyCount = Math.min(10, showcase.total - showcase.items.length);
    const showcaseId = showcase.getSelectorId();
    const forceUpdate = useForceUpdate();

    cards =
        cards ||
        Cards({
            items: showcase.items,
            divClass: slideClassName,
            eventParams: {
                showcase_url: showcase.getUrl(),
                showcase_name: showcase.title,
            },
        });
    const dummyCards =
        dummyCount > 0
            ? DummyCards({
                  count: dummyCount,
                  divClass: slideClassName,
                  cardClasses: getSliderStubClass(showcase.items[0], showcase.SLIDER_STUB_CLASS),
              })
            : '';
    const props = getPropsByElements(showcase.getElementsInfo().elements);

    return (
        <Swiper
            id={showcaseId}
            className={sliderClassName + ' ' + itemClass}
            onSwiper={(swiperRef) => {
                updateActions(swiperRef, showcase);
                // noinspection JSUnresolvedFunction
                const slidesPerViewDynamic =
                    showcase.SLIDER_STUB_CLASS === 'cover--episodes' ? 8 : swiperRef.slidesPerViewDynamic();
                if (slidesPerViewDynamic > showcase.items.length && showcase.items.length < showcase.total) {
                    showcase.loadItems(api, 8).then(() => {
                        forceUpdate();
                        onShowcaseUpdate?.();
                        setTimeout(() => {
                            updateActions(swiperRef, showcase);
                        }, 0);
                    });
                }
                if (typeof onSwiperInit === 'function') {
                    onSwiperInit(swiperRef);
                }
            }}
            modules={[Navigation]}
            speed={400}
            roundLengths={true}
            spaceBetween={spaceBetween}
            slidesPerView={slidesPerView}
            slidesPerGroup={1}
            loop={loop}
            wrapperClass={wrapperClassName}
            slideClass={slideClassName}
            navigation={{
                prevEl: `#${showcaseId}-prevButton`,
                nextEl: `#${showcaseId}-nextButton`,
                disabledClass: disabledBtnClass,
            }}
            breakpoints={
                needBreakpoints
                    ? breakpoints || {
                          1801: {
                              slidesPerView: props.large ? props.large : props.desktop,
                              slidesPerGroup: props.large ? props.large : props.desktop,
                          },
                          1401: {
                              slidesPerView: props.desktop,
                              slidesPerGroup: props.desktop,
                          },
                          1001: {
                              slidesPerView: props.laptop,
                              slidesPerGroup: props.laptop,
                          },
                          0: {
                              slidesPerView: 'auto',
                              slidesPerGroup: 1,
                              spaceBetween: 0,
                          },
                      }
                    : null
            }
            onSlideNextTransitionStart={(swiper) => {
                if (!swiper) {
                    return;
                }
                // noinspection JSUnresolvedFunction
                if (
                    swiper.previousIndex + 2 * swiper.slidesPerViewDynamic() > showcase.items.length &&
                    showcase.items.length < showcase.total
                ) {
                    showcase.loadItems(api, 48).then(() => {
                        forceUpdate();
                        onShowcaseUpdate?.();
                    });
                }
            }}
        >
            {cards}
            {dummyCards}
            {dummyCount === 0 && showcase.items.length === 0 && showcase.subtitle ? (
                <div className="catalog__item-subtitle">{showcase.subtitle}</div>
            ) : (
                ''
            )}
        </Swiper>
    );
}
