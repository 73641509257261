import styles from './../catalog/Catalog.module.scss';
import Cards from '@/components/v3/Cards';
import { useState } from 'react';
import Pagination from '@/components/pagination/Pagination';
import { useApiStore } from '@/src/stores/RootStoreProvider';

/**
 * @param {Showcase} showcase
 */
export default function ShowcasePlain({ showcase }) {
    const { api } = useApiStore();
    const [isLoading, setIsLoading] = useState(false);

    const onLoadItems = async () => {
        setIsLoading(true);
        await showcase.loadItems(api, 24); // TODO: x2
        setIsLoading(false);
    };

    return (
        <section
            className={
                styles['catalog'] + ' catalog--no-scroll-x ' + showcase.class + ' ' + showcase.getItemClassPlain()
            }
        >
            <div className={styles['catalog__item']} data-name={showcase.title}>
                {showcase.showHeader && (
                    <div className={styles['catalog__item-header']}>
                        <div className={styles['catalog__item-title']}>
                            {showcase.title}
                            {showcase.subtitle && <span className="h-opacity--half">{showcase.subtitle}</span>}
                        </div>
                    </div>
                )}
                <div className={'catalog__item-wrapper'}>
                    <div className={styles['catalog__item-inner']}>
                        <Cards items={showcase.items} divClass={null} />
                    </div>
                </div>
            </div>
            {showcase.items.length < showcase.total && <Pagination isLoading={isLoading} onLoadItems={onLoadItems} />}
        </section>
    );
}
