import Badge from '@/components/badge/Badge';

/**
 * @param {BaseModel} model
 * @param {Offers} offers
 */
export default function SliderBadge({ model, offers }) {
    if (!model.isAvailable() && offers.isFree()) {
        return <Badge title={'Доступно'} modifier={'badge--cherry slider__badge'} />;
    }
    if (offers.isTNBAvailable()) {
        return (
            <Badge title={offers.offer.stock.getPeriodText()} modifier={'badge--yellow slider__badge'} icon={'gift'} />
        );
    }
    if (!model.isAvailable() && offers.getDiscount()) {
        return <Badge title={`Скидка ${offers.getDiscount()} %`} modifier={'badge--yellow slider__badge'} />;
    }
    return null;
}
