import Tabs from '@/components/tabs/Tabs';
import Season from '@/src/api/v3/models/Season';
import ShowcasePlain from '@/components/v3/ShowcasePlain';
import { useEffect, useRef } from 'react';
import useForceUpdate from '@/src/hooks/useForceUpdate';
import { useApiStore } from '@/src/stores/RootStoreProvider';

const RESERVE_TABS = 1; // Количество табов до подгрузки новых в витрину

export default function ShowcaseTabs({ showcase }) {
    const { api } = useApiStore();
    const forceUpdate = useForceUpdate();
    const loadingRef = useRef(false);
    const onNext = (index) => {
        if (
            !loadingRef.current &&
            showcase.items.length < showcase.total &&
            index + RESERVE_TABS >= showcase.items.length
        ) {
            loadingRef.current = true;
            showcase.loadItems(api, 8).then(() => {
                forceUpdate();
                loadingRef.current = false;
            });
        }
    };
    let tabs = [];
    showcase.items.forEach((item) => {
        if (item.children && item.children.showcases.length > 0) {
            let subtitle = null;
            if (item instanceof Season) {
                subtitle = item.releasedAt ? item.releasedAt.getFullYear() : null;
            }
            const showcase = item.children.showcases[0];
            showcase.showHeader = false;
            tabs.push({
                id: tabs.length,
                title: item.title,
                subtitle: subtitle,
                selectTitle: item.title + (subtitle ? ` (${subtitle})` : ''),
                content: <ShowcasePlain showcase={showcase} />,
            });
        }
    });
    const dummyCount = showcase.total - showcase.items.length;
    if (dummyCount > 0) {
        [...Array(dummyCount)].forEach(() => {
            tabs.push({
                title: '...',
                subtitle: '\u00A0',
                content: '',
            });
        });
    }

    useEffect(() => {
        if (!api) {
            return;
        }
        const dummyCount = showcase.total - showcase.items.length;
        // TODO: Оптимизировать. Загружать только если видно вкладку
        if (dummyCount > 0) {
            showcase.loadItems(api, 8).then(() => {
                forceUpdate();
            });
        }
    }, [api, forceUpdate, showcase]);

    return (
        <Tabs
            modifier={'tabs--big-buttons tabs--with-cards'}
            tabs={tabs}
            onNext={onNext}
            needControlsContainer={false}
        />
    );
}
