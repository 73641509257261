import styles from './../slider/Slider.module.scss';
import Button from '@/components/button/Button';
import { Swiper, SwiperSlide } from 'swiper/react';
import useForceUpdate from '@/src/hooks/useForceUpdate';
import Icon from '@/components/icon/Icon';
import Link from 'next/link';
import PinChecker from '@/src/checkers/PinChecker';
import { observer } from 'mobx-react';
import SliderBadge from '@/components/v3/cards/common/SliderBadge';
import Asset from '@/src/api/v3/models/Asset';
import Subscription from '@/src/api/v3/models/Subscription';
import { useApiStore } from '@/src/stores/RootStoreProvider';
import { useRef } from 'react';
import { Autoplay, Navigation } from 'swiper/modules';
import { gtmEvent } from '@/src/modules/events/GTM';

/**
 * @param {Showcase} showcase
 */
function ShowcaseSliderBig({ showcase }) {
    const showcaseId = showcase.getSelectorId();

    /**
     * @param {BaseModel} item
     */
    const onClick = (item) => {
        const eventParams = {
            showcase_url: showcase.getUrl(),
            showcase_name: showcase.title,
        };
        if (item.EVENT_ITEM_CATEGORY) {
            gtmEvent('showcase', 'item_click', {
                item_id: item.id,
                item_name: item.title,
                item_category: item.EVENT_ITEM_CATEGORY,
                ...eventParams,
            });
        }
    };

    if (showcase.total === 1) {
        const item = showcase.items[0];
        return (
            <section className={styles['slider']}>
                <SwiperSlide className={'slider__item'} onClick={() => onClick(item)}>
                    <BigSlideObserved item={item} />
                </SwiperSlide>
            </section>
        );
    }

    return (
        <section className={styles['slider']}>
            {showcase.showHeader && showcase.title && <h2 className="slider__title">{showcase.title}</h2>}
            <Slider showcase={showcase} onClick={onClick} />
            <div className={styles['slider__action']}>
                <Button
                    type={'button'}
                    icon={'arrow-left'}
                    title={'Предыдущий слайд'}
                    className={
                        'slider__item-slider-arrow slider__item-slider-arrow--prev button--secondary button--small button--icon-only'
                    }
                    id={`${showcaseId}-prevButton`}
                />
                <Button
                    type={'button'}
                    icon={'arrow-right'}
                    title={'Следующий слайд'}
                    className={
                        'slider__item-slider-arrow slider__item-slider-arrow--next button--secondary button--small button--icon-only'
                    }
                    id={`${showcaseId}-nextButton`}
                />
            </div>
        </section>
    );
}

/**
 * @param {Showcase} showcase
 * @param {Function} onClick
 */
function Slider({ showcase, onClick }) {
    const { api } = useApiStore();
    const forceUpdate = useForceUpdate();

    const showcaseLoadingRef = useRef(false);
    const dummyCount = Math.min(showcase.total - showcase.items.length, 12);
    const showcaseId = showcase.getSelectorId();

    const cards = showcase.items.map((item, index) => {
        return (
            <SwiperSlide className={'slider__item'} key={item.uniqueId} onClick={() => onClick(item)}>
                <BigSlideObserved item={item} lazyLoad={index > 0} />
            </SwiperSlide>
        );
    });

    const dummyCards =
        dummyCount > 0
            ? [...Array(dummyCount)].map((_, index) => (
                  <SwiperSlide className={'slider__item'} key={'dummy-' + index /* NOSONAR */}>
                      <EmptySlide />
                  </SwiperSlide>
              ))
            : '';

    return (
        <Swiper
            id={showcaseId}
            className={'slider__wrap'}
            modules={[Autoplay, Navigation]}
            speed={600}
            slidesPerView={'auto'}
            spaceBetween={0}
            roundLengths={true}
            centeredSlides={false}
            loop={false}
            slideClass={'slider__item'}
            slideActiveClass={'slider__item--active'}
            autoplay={{
                delay: 6000,
                pauseOnMouseEnter: true,
            }}
            navigation={{
                prevEl: `#${showcaseId}-prevButton`,
                nextEl: `#${showcaseId}-nextButton`,
            }}
            style={{ overflow: 'visible' }}
            onSlideNextTransitionStart={(swiper) => {
                if (
                    swiper.previousIndex + 2 * swiper.slidesPerViewDynamic() >= showcase.items.length &&
                    showcase.items.length < showcase.total &&
                    !showcaseLoadingRef.current
                ) {
                    showcaseLoadingRef.current = true;
                    showcase.loadItems(api, 12).then(() => {
                        showcaseLoadingRef.current = false;
                        forceUpdate();
                    });
                }
            }}
        >
            {cards}
            {dummyCards}
        </Swiper>
    );
}

function EmptySlide() {
    return (
        <a className={'slider__item-inner slider__item-inner--fixed'} href={'#'}>
            <picture>
                <source srcSet="/assets/img/slide-desk-no-image.png" media="(min-width: 1101px)" />
                <img className="slider__item-img" src="/assets/img/slide-tablet-no-image.png" alt="" />
            </picture>
        </a>
    );
}

const BigSlideObserved = observer(BigSlide);

const emptyImage = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

function BigSlide({ item, lazyLoad }) {
    const isUnderPin = item.isAdult();
    const desktopImage = item.getSliderSrc({ size: { width: 1564, height: 367 } });
    const mobileImage = item.getSliderMobileSrc({ size: { width: 1000, height: 413 } });
    const desktopProxyImage = `/_next/image?url=${encodeURI(desktopImage)}&w=1920&q=75`;
    const mobileProxyImage = `/_next/image?url=${encodeURI(mobileImage)}&w=1920&q=75`;
    return (
        <PinChecker checked={isUnderPin}>
            <Link href={item.getUrl()} className={'slider__item-inner slider__item-inner--fixed'}>
                {isUnderPin ? (
                    <>
                        <picture>
                            <source srcSet="/assets/img/slide-desk-no-image.png" media="(min-width: 1101px)" />
                            <img
                                className="slider__item-img"
                                src="/assets/img/slide-tablet-no-image.png"
                                alt={item.title}
                                loading={'lazy'}
                            />
                        </picture>
                        <Icon id={'parental'} modifier={'slider__icon'} />
                    </>
                ) : (
                    <picture>
                        <source
                            srcSet={lazyLoad ? emptyImage : desktopProxyImage}
                            data-srcset={desktopProxyImage}
                            media="(min-width: 1101px)"
                        />
                        <img
                            className={styles['slider__item-img'] + (lazyLoad ? ' lazyload' : '')}
                            src={lazyLoad ? emptyImage : mobileProxyImage}
                            data-src={mobileProxyImage}
                            alt={item.title}
                        />
                    </picture>
                )}
                <Icon id={`age-${item.age}`} modifier={'icon--age-large slider__rating'} />
                {(item instanceof Asset || item instanceof Subscription) && (
                    <SliderBadge model={item} offers={item.offers} />
                )}
            </Link>
        </PinChecker>
    );
}

export default observer(ShowcaseSliderBig);
